import { axiosInstance } from '@/utils/axios'
import { API_HTTP, BE_API_KEY, NODE_API_HTTP } from '@/common/constants'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export class StripeApiService {
    constructor() {
        this.nodeApi = `${NODE_API_HTTP}/api/v1`
    }

    async createSession(email, successUrl, cancelUrl, lineItems, isTrial, mode) {
        let url = `${this.nodeApi}/sessions`
        if (mode) url = `${url}?mode=${mode}`
        const data = snakecaseKeys({ email, successUrl, cancelUrl, lineItems, isTrial })
        const { data: session } = await axiosInstance.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return camelcaseKeys(session)
    }

    async upgradeSubscription(subscriptionId, priceId, tenantId, subscriptionType) {
        const url = `${this.nodeApi}/subscriptions/${subscriptionId}`
        const data = { priceId, tenantId, subscriptionType }
        const { data: subscription } = await axiosInstance.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return camelcaseKeys(subscription)
    }

    async generatePaymentIntent(price) {
        const url = `${this.nodeApi}/subscriptions/create-payment-intent`
        const { data: paymentIntent } = await axiosInstance.post(url, { price }, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BE_API_KEY,
                origin: location.origin
            }
        })
        return camelcaseKeys(paymentIntent)
    }

    async cancelPaymentIntent(paymentIntentId) {
        const url = `${this.nodeApi}/subscriptions/cancel-payment-intent`
        const { data: cancelledPaymentIntent } = await axiosInstance.post(url, snakecaseKeys({ paymentIntentId }), {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BE_API_KEY,
                origin: location.origin
            }
        })
        return camelcaseKeys(cancelledPaymentIntent)
    }

    async getProducts() {
        const url = `${this.nodeApi}/products`
        const { data: products } = await axiosInstance.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const subscriptions = products
            .filter((fp) => fp?.metadata?.subscription)
        subscriptions.sort((a, b) => {
            if (Number(a?.metadata?.order) < Number(b?.metadata?.order)) return -1
            else if (Number(a?.metadata?.order) > Number(b?.metadata?.order)) return 1
            return 0
        })
        const hardware = products.filter((fp) => fp?.metadata?.hardware)
        return { subscriptions, hardware }
    }

    async cancelSubscription(subscriptionId) {
        const url = `${this.nodeApi}/subscriptions/${subscriptionId}`
        try {
            const { data } = await axiosInstance.delete(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return true
        } catch (e) {
            console.error(e)
            return false
        }
    }
}
